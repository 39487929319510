import { createApp, ref } from 'vue';

import { ScQotdBanner } from '@pch/surffers-components';

const qotdApp = createApp({
    setup() {
        
        let api = window?.PCH?.surveys?.questionOfDayConfig?.question_of_day_api;

        let isMobile = !(window.matchMedia('(min-width: 33.75rem)').matches);

        const qotdQuestionAvailable = ref(false);
        const qotdDone = ref(false);
        const buttonHref = ref(`//offers.pch.com/survey/question-set/qotd`);

        const title = ref('Question of the Day');
        const subtitle = ref('Answer today\'s question');
        const buttonText = ref('Let\'s Go');


        function gotoQotd() {
            utag.link({ "tealium_event" : "qotd_lets_go" }, null, [PCHGA4TagId]);
            setTimeout(() => {
                window.location.href = buttonHref.value;
            }, 300);
        }

        function fromApi(data) {
            title.value = data.title || title.value;
            subtitle.value = `${isMobile ? '' : '- '}${data.sub_title || subtitle.value}`;
            buttonText.value = data.button_text || buttonText.value;
            buttonHref.value = data.button_href || buttonHref.value;
            qotdQuestionAvailable.value = data.qotd_questions || qotdQuestionAvailable.value;
            qotdDone.value = data.qotd_done || qotdDone.value;
        }

        function getQotd() {

            fetch(api, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
            .then(response => response.ok ? response.json() : Promise.reject(response))
            .then(payload => payload.status === 'success' ? payload.data : Promise.reject(payload))
            .then(data => fromApi(data))
            .catch(error => console.warn('qotd api', error));
        }

        if(api) {
            getQotd();
        }

        return {
            qotdQuestionAvailable,
            qotdDone,
            buttonHref,
            title,
            subtitle,
            buttonText,
            gotoQotd,
            isMobile
        };
    }
});
qotdApp.component('sc-qotd-banner', ScQotdBanner);
qotdApp.mount('#qotd');